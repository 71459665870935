import React from 'react'
import { IonButton, IonImg, IonSlides, IonSlide, IonIcon } from '@ionic/react'
import { getUserData } from '../../firebaseConfig';
import { arrowForwardCircle } from 'ionicons/icons';

import './Principal.css';

const Principal: React.FC = () => {

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };

    return (
        <IonSlides pager={true} options={slideOpts}>
            <IonSlide>
                <div className="item">
                    <IonImg style={{ width: '70%', margin: '0 auto' }} src="/assets/s9.jpg" />
                    <IonIcon className="main-icon" icon={arrowForwardCircle}/>
                </div>
            </IonSlide>
            <IonSlide>
                <div className="item">
                    <IonImg style={{ width: '70%', margin: '0 auto' }} src="/assets/s4.jpg" />
                    <IonIcon className="main-icon" icon={arrowForwardCircle}/>
                </div>
            </IonSlide>
            <IonSlide>
                <div className="item">
                    <IonImg style={{ width: '70%', margin: '0 auto' }} src="/assets/s5.jpg" />
                    <IonIcon className="main-icon" icon={arrowForwardCircle}/>
                </div>
            </IonSlide>
            <IonSlide>
                <div className="item">
                    <IonImg style={{ width: '70%', margin: '0 auto' }} src="/assets/s7.jpg" />
                    <p style={{ padding: '1rem', textAlign: 'center', fontSize: '.8rem' }}>Si resides dentro del municipio de Guasave Sinaloa, podrás completar el siguiente formulario para detectar si eres propenso a tener COVID-19 y recibir atención por parte del gobierno municipal.</p>
                    <IonButton onClick={handleSubmit} color="primary" style={{ marginTop: '1rem' }}  expand="full">Comenzar Formulario</IonButton>
                </div>
            </IonSlide>
        </IonSlides>
    )

    async function handleSubmit() {
        try {
            getUserData().update({ step: 1 })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Principal