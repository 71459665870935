import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonSlides, IonSlide, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Page.css';
import { sendSms, getMain } from '../firebaseConfig';

const SendApp: React.FC = () => {

    const [phone,setphone] = useState<string>()

    const [sms, setsms] = useState<string>()

    useEffect(() => {
        getMain().get().then(snap => {
            const item = snap.data()
            setsms(item?.sms)
        })
    },[])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Enviar Aplicación</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Enviar Aplicación</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonList>
                    <p style={{ padding: '1rem', textAlign: 'center' }}>Envía esta aplicación a las personas residentes al municipio de Guasave Sinaloa con las que has tenido contacto en los últimos 14 días, envíales un mensaje anónimo para asegurarnos que no esté contagiado de COVID-19 y complete este formulario.</p>
                    <IonItem>
                        <IonLabel>Número telefónico</IonLabel>
                        <IonInput
                            value={phone}
                            onIonChange={e => setphone(e.detail.value!)}
                            placeholder="Teléfono"
                            type="tel"
                            inputMode="tel"
                        />
                    </IonItem>
                    <IonItem>
                        <IonButton onClick={handleSubmit} size="default" style={{ width: '100%' }} expand="full" color="primary" shape="round">Enviar</IonButton>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );

    async function handleSubmit () {
        try {
            if (phone) {
                const item = {
                    phone: `+52${phone}`,
                    message: sms,
                    status: 'pending',
                    timestamp: moment().valueOf(),
                    from: '+14156302182'
                }
                sendSms(item).then(() => {
                    alert('Mensaje enviado.')
                    window.location.href = '/login'
                })
            } else {
                alert('Escribe un teléfono para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
};

export default SendApp;
