import React, { useState } from 'react';
import { IonContent, IonPage, IonButton, IonIcon, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonList, IonItem, IonInput, IonLabel } from '@ionic/react';
import { lockClosedOutline } from 'ionicons/icons';
import moment from 'moment';
import { sendSms, getSingleUser } from '../firebaseConfig';

import './Recovery.css';

const Recovery: React.FC = () => {

    const [phone, setphone] = useState<string>()

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/login" />
                    </IonButtons>
                    <IonTitle>Recuperar Contraseña</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList className="container">
                    <IonIcon color="primary" className="icon" icon={lockClosedOutline} />
                    <IonItem className="input">
                        <IonLabel>Teléfono</IonLabel>
                        <IonInput
                            placeholder="Teléfono"
                            value={phone}
                            onIonChange={e => setphone(e.detail.value || '')}
                            type="tel"
                            inputMode="tel"
                        />
                    </IonItem>
                    <IonButton onClick={handleSubmit} expand="block" color="primary" shape="round">Enviar Contraseña</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    );

    async function handleSubmit () {
        try {
            if (phone) {
                getSingleUser(phone).then(snap => {
                    if (!snap.empty) {
                        const data = snap.docs[0].data()
                        const item = {
                            phone: `+52${data.phone}`,
                            message: `Hola ${data.name}, tu contraseña es ${data.password}`,
                            status: 'pending',
                            timestamp: moment().valueOf(),
                            from: '+14156302182'
                        }
                        sendSms(item).then(() => {
                            alert('Revisa tus mensajes para ver tu contraseña.')
                            window.location.href = '/login'
                        })
                    } else {
                        alert('No existe un usuario con este número telefónico.')
                    }
                })
            } else {
                alert('Escribe un teléfono para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
};

export default Recovery;