import React, { useState } from 'react'
import { IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react'
import { searchOutline } from 'ionicons/icons';
import axios from 'axios';
import * as _ from 'lodash';
import { getUserData } from '../../firebaseConfig';

import './Slides.css';

interface AddressComponents {
    long_name: null | string,
    short_name: null | string
}

interface AddressList {
    place_id: null | string,
    formatted_address: null | string,
    lng: null | number,
    lat: null | number,
    geometry: {
        location: {
            lng: number,
            lat: number
        }
    },
    address_components: AddressComponents[]
}

const Zero: React.FC = () => {

    const [age,setage] = useState<string>()
    const [gender, setgender] = useState<string>()
    const [address, setaddress] = useState<string>()
    const [realAddress, setrealAddress] = useState<{ place_id: null | string, formatted_address: null | string, lng: null | number, lat: null | number, locality: null | string}>({ place_id: null, formatted_address: null, lng: null, lat: null, locality: null })
    const [addressList,setaddressList] = useState<AddressList[]>([])

    return (
        <IonList>
            <h3>Cuentanos un poco de ti</h3>
            <IonItem>
                <IonLabel>¿Cuantos años tienes?</IonLabel>
                <IonInput
                    placeholder="Edad"
                    value={age}
                    onIonChange={e => setage(e.detail.value || '')}
                    type="number"
                    inputMode="numeric"
                />
            </IonItem>
            <IonItem>
                <IonLabel>¿Cuál es tu genero?</IonLabel>
                <IonSelect value={gender} okText="Seleccionar" cancelText="Cancelar" onIonChange={e => setgender(e.detail.value)}>
                    <IonSelectOption value="">** Selecciona una opción **</IonSelectOption>
                    <IonSelectOption value="Masculino">Masculino</IonSelectOption>
                    <IonSelectOption value="Femenino">Femenino</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem lines="none">
                <IonLabel position="stacked">¿Cuál es tu dirección?</IonLabel>
                <div style={{ display: 'flex', width: '100%' }}>
                    <IonInput
                        placeholder="Dirección"
                        value={address}
                        onIonChange={e => setaddress(e.detail.value || '')}
                        type="text"
                        inputMode="search"
                        style={{ flex: 1 }}
                    />
                    <IonButton onClick={handleSearch} slot="end">
                        <IonIcon icon={searchOutline} />
                    </IonButton>
                </div>
            </IonItem>
            {
                addressList ? <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonList>
                                {
                                    addressList.map((item) => <IonItem onClick={() => setrealAddress(prevState => ({ ...prevState, place_id: item.place_id, formatted_address: item.formatted_address, lat: item.geometry.location.lat, lng: item.geometry.location.lng, locality: item.address_components[3]?.long_name }))} button={true} color={item.place_id === realAddress.place_id ? 'primary' : ''} key={item.place_id ? item.place_id : ''}>
                                        <p>{item.formatted_address}</p>
                                    </IonItem>)
                                }
                            </IonList>
                        </IonCol>
                        <IonCol size="12">
                            {
                                realAddress.place_id ? '' : <p>** Selecciona una dirección para continuar **</p>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid> : ''
            }
            <IonButton onClick={handleSubmit} size="large" color="primary" style={{ marginTop: '1rem' }}  expand="block">Continuar</IonButton>
        </IonList>
    )

    async function handleSubmit () {
        try {
            if (age && gender && realAddress.place_id) {
                if (_.includes(realAddress.formatted_address, 'Guasave')) {
                    getUserData().update({
                        age,
                        gender,
                        address: realAddress.formatted_address,
                        lat: realAddress.lat,
                        lng: realAddress.lng,
                        step: 2,
                    }).then(() => {
                        alert('Continuemos...')
                    })
                } else {
                    alert('La dirección seleccionada no corresponde a la localidad de Guasave Sinaloa')
                }
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSearch () {
        try {
            if (address) {
                const processed = _.replace(address, ' ', '+');
                await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${processed}&key=AIzaSyCrTl05Ajn7uo9_imbmH6SzslZQk1JW6xs`).then(response => {
                    const results = response.data.results;
                    console.log(results)
                    if (_.size(results) > 1) {
                        setaddressList(results)
                    }else if (_.size(results) === 1) {
                        setaddressList(results)
                        const item = results[0]
                        setrealAddress(prevState => ({...prevState, place_id: item.place_id, formatted_address: item.formatted_address, lat: item.geometry.location.lat, lng: item.geometry.location.lng, locality: item.address_components[3]?.long_name }))
                    } else {
                        alert('Dirección no encontrada')
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Zero