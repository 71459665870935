import React, { useState, useEffect } from 'react'
import { IonPage, IonContent, IonButton, IonIcon, IonImg, IonText, IonHeader, IonTitle, IonSlides, IonSlide } from '@ionic/react'
import { personAddOutline, personOutline, statsChartOutline } from 'ionicons/icons'

import './Home.css'
import { getbanner } from '../firebaseConfig'

interface Banner {
    img: string,
    message: string,
    id: string
}

const Home: React.FC = () => {

    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        autoplay: true
    };

    const [banner,setbanner] = useState<Banner[]>()

    useEffect(() => {
        getbanner().get().then(snap => {
            const data = snap.docs.map(doc => ({ img: doc.data().img, message: doc.data().message, id: doc.data().id }))
            setbanner(data)
        })
    },[])

    return (
        <IonPage>
            <IonContent>
                <IonHeader>
                    {banner ? <IonSlides pager={false} options={slideOpts}>
                        {
                            banner.map(item => <IonSlide key={item.id}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    {item.img ? <IonImg src={item.img} /> : ''}
                                    {item.message ? <p>{item.message}</p> : ''}
                                </div>
                            </IonSlide>)
                        }
                    </IonSlides> : ''}
                </IonHeader>
                <div style={{ flex: 1, padding: '1rem' }}>
                    <IonImg className="logo" src="/assets/logo-new.png" />
                    <IonText color="primary">
                        <h3 style={{ textAlign: 'center' }}>¿Crees que cuentas con los sintomas habituales relacionados al COVID-19?</h3>
                    </IonText>
                    <IonText>
                        <p style={{ textAlign: 'center' }}>Realiza un cuestionario en menos de 5 minutos y recibe atención de tu gobierno de Guasave Sinaloa.</p>
                    </IonText>
                    <IonButton routerLink="/registry" routerDirection="forward" className="login-btn" expand="block" size="large" color="primary">
                        <IonIcon slot="start" icon={personAddOutline} />
                        Nuevo Usuario
                    </IonButton>
                    <IonButton routerLink="/login" routerDirection="forward" fill="outline" className="login-btn" expand="block" size="large" color="primary">
                        <IonIcon slot="start" icon={personOutline} />
                        Iniciar Sesión
                    </IonButton>
                    <IonButton style={{ marginTop: 'auto' }} href="https://rastreo-covid.web.app/" fill="clear" className="login-btn" expand="block" color="primary">
                        <IonIcon slot="start" icon={statsChartOutline} />
                        Dashboard
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Home