import React, { useState } from 'react'
import { IonButton, IonList, IonItem, IonLabel, IonInput } from '@ionic/react'
import moment from 'moment';
import { sendSms } from '../../firebaseConfig'

const Three: React.FC = () => {

    const [phone,setphone] = useState<string>()

    return (
        <div>

            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '2rem' }}>
                <h3>Terminamos.</h3>
                <p style={{ textAlign: 'center' }}>El proceso a terminado, tus datos e información está siendo evaluada por el gobierno de Guasave Sinaloa, si con la información proporcionada eres propenso a tener COVID 19 serás contactado para realizar pruebas médicas en tu domicilio.</p>

                <IonList>
                    <p style={{ padding: '1rem', textAlign: 'center' }}>Envía esta aplicación a las personas residentes al municipio de Guasave Sinaloa con las que has tenido contacto en los últimos 14 días, envíales un mensaje anónimo para asegurarnos que no esté contagiado de COVID-19 y complete este formulario.</p>
                    <IonItem>
                        <IonLabel>Número telefónico</IonLabel>
                        <IonInput
                            value={phone}
                            onIonChange={e => setphone(e.detail.value!)}
                            placeholder="Teléfono"
                            type="tel"
                            inputMode="tel"
                        />
                    </IonItem>
                    <IonButton fill="outline" onClick={handleSubmit} size="default" style={{ width: '100%' }} expand="full" color="primary" shape="round">Enviar</IonButton>
                </IonList>

                <IonButton routerLink="/recomendations" routerDirection="forward" size="large" color="primary" style={{ marginTop: '1rem' }} expand="block">Ver Recomendaciones</IonButton>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (phone) {
                const item = {
                    phone: `+52${phone}`,
                    message: `Descarga la aplicación para el rastreo del COVID en Guasave`,
                    status: 'pending',
                    timestamp: moment().valueOf(),
                    from: '+14156302182'
                }
                sendSms(item).then(() => {
                    alert('Aplicación enviada')
                })
            } else {
                alert('Escribe un teléfono para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Three