import React, { useState } from 'react';
import { IonContent, IonPage, IonButton, IonIcon, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonList, IonItem, IonInput, IonLabel } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { createUser } from '../firebaseConfig';

import './Login.css';

const Registry: React.FC = () => {

    const [name, setname] = useState<string>()
    const [phone, setphone] = useState<string>()
    const [password, setpassword] = useState<string>()

    const [loading,setloading] = useState<boolean>(false)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Registro</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList className="container">
                    <IonIcon color="primary" className="icon" icon={personCircleOutline} />
                    <IonItem className="input">
                        <IonLabel>Nombre</IonLabel>
                        <IonInput
                            placeholder="Nombre"
                            value={name}
                            onIonChange={e => setname(e.detail.value || '')}
                            type="text"
                            inputMode="text"
                        />
                    </IonItem>
                    <IonItem className="input">
                        <IonLabel>Teléfono</IonLabel>
                        <IonInput
                            placeholder="Teléfono"
                            value={phone}
                            onIonChange={e => setphone(e.detail.value || '')}
                            type="tel"
                            inputMode="tel"
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Contraseña</IonLabel>
                        <IonInput
                            placeholder="Contraseña"
                            value={password}
                            onIonChange={e => setpassword(e.detail.value || '')}
                            type="password"
                            inputMode="text"
                        />
                    </IonItem>
                    <IonLabel style={{ textAlign: 'right', marginTop: '.1rem', marginBottom: '1rem' }} position="stacked">Al menos 6 caracteres</IonLabel>
                    <IonButton disabled={loading} onClick={handleSubmit} expand="block" color="primary" shape="round">Registrarme</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    );

    async function handleSubmit() {
        try {
            setloading(true)
            if (phone && password && name) {
                createUser(phone, password, name || '').then(() => {
                    window.location.href = '/'
                }).catch(error => {
                    alert(error.message)
                    setloading(false)
                })
            } else {
                alert('Completa todos los campos')
                setloading(false)
            }
        } catch (error) {
            alert(error.message)
            setloading(false)
        }
    }
};

export default Registry;