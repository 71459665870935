import React, { useState } from 'react';
import { IonContent, IonPage, IonButton, IonIcon, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonList, IonItem, IonInput, IonLabel } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import { login } from '../firebaseConfig';

import './Login.css';

const Login: React.FC = () => {

    const [phone, setphone] = useState<string>()
    const [password, setpassword] = useState<string>()
    
    const [loading,setloading] = useState<boolean>(false)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Inicio de Sesión</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList className="container">
                    <IonIcon color="primary" className="icon" icon={personCircleOutline} />
                    <IonItem className="input">
                        <IonLabel>Teléfono</IonLabel>
                        <IonInput
                            placeholder="Teléfono"
                            value={phone}
                            onIonChange={e => setphone(e.detail.value || '')}
                            type="tel"
                            inputMode="tel"
                        />
                    </IonItem>
                    <IonItem className="input">
                        <IonLabel>Contraseña</IonLabel>
                        <IonInput
                            placeholder="Contraseña"
                            value={password}
                            onIonChange={e => setpassword(e.detail.value || '')}
                            type="password"
                            inputMode="text"
                        />
                    </IonItem>
                    <IonButton disabled={loading} onClick={handleSubmit} expand="block" color="primary" shape="round">Iniciar Sesión</IonButton>
                    <IonButton routerLink="/recovery" routerDirection="forward" expand="block" shape="round" fill="outline">Recuperar Contraseña</IonButton>
                </IonList>
            </IonContent>
        </IonPage>
    );

    async function handleSubmit() {
        try {
            setloading(true)
            if (phone && password) {
                login(phone, password).then(() => {
                    window.location.href = '/'
                }).catch(error => {
                    alert(error.message)
                    setloading(false)
                })
            } else {
                alert('Completa todos los campos')
                setloading(false)
            }
        } catch (error) {
            alert(error.message)
            setloading(false)
        }
    }
};

export default Login;