import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import { getUserData } from '../firebaseConfig';
import Zero from '../components/slides/Zero';
import One from '../components/slides/One';
import Two from '../components/slides/Two';
import Three from '../components/slides/Three';
import Principal from '../components/slides/Principal';

const Page: React.FC = () => {

  const [user, setuser] = useState<{ step: null | number }>({ step: null })

  useEffect(() => {
    getUserData().onSnapshot(snap => {
      const item = snap.data()
      setuser(prevstate => ({ ...prevstate, step: item?.step }))
    })
  },[])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Rastreo Covid 19 - Guasave Sinaloa</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Rastreo Covid 19 - Guasave Sinaloa</IonTitle>
          </IonToolbar>
        </IonHeader>
        {
          user.step === 0 ? <Principal /> :
          user.step === 1 ? <Zero /> :
          user.step === 2 ? <One /> :
          user.step === 3 ? <Two /> :
          user.step === 4 ? <Three /> : ''
        }
      </IonContent>
    </IonPage>
  );
};

export default Page;
