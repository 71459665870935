import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonSlides, IonSlide, IonList, IonItem, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import './Page.css';
import { globeOutline, mailOutline, logoFacebook, logoTwitter } from 'ionicons/icons';

const Contact: React.FC = () => {

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Contacto - Guasave Sinaloa</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Contacto - Guasave Sinaloa</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonList style={{ marginTop: 20 }}>
                    <IonItem href="http://guasave.gob.mx/s/">
                        <IonIcon style={{ marginRight: 20 }} icon={globeOutline}/>
                        <IonLabel>http://guasave.gob.mx/s/</IonLabel>
                    </IonItem>
                    <IonItem href="mailto:contacto@guasave.gob.mx">
                        <IonIcon style={{ marginRight: 20 }} icon={mailOutline}/>
                        <IonLabel>contacto@guasave.gob.mx</IonLabel>
                    </IonItem>
                    <IonItem href="https://www.facebook.com/ayuntamientodeguasave">
                        <IonIcon style={{ marginRight: 20 }} icon={logoFacebook}/>
                        <IonLabel>@ayuntamientodeguasave</IonLabel>
                    </IonItem>
                    <IonItem href="https://twitter.com/AytoGuasave">
                        <IonIcon style={{ marginRight: 20 }} icon={logoTwitter}/>
                        <IonLabel>@AytoGuasave</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Contact;
