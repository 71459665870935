import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { mailOutline, mailSharp, helpOutline, helpSharp, medkitOutline, medkitSharp, logOutOutline, appsOutline, appsSharp } from 'ionicons/icons';
import './Menu.css';
import { getUserData, logout } from '../firebaseConfig';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Cuestionario',
    url: '/main',
    iosIcon: helpOutline,
    mdIcon: helpSharp
  },
  {
    title: 'Recomendaciones',
    url: '/recomendations',
    iosIcon: medkitOutline,
    mdIcon: medkitSharp
  },
  {
    title: 'Enviar Aplicación',
    url: '/send-app',
    iosIcon: appsOutline,
    mdIcon: appsSharp
  },
  {
    title: 'Contacto',
    url: '/contact',
    iosIcon: mailOutline,
    mdIcon: mailSharp
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  const [user,setuser] = useState<{ name: null | string, phone: null | string }>({ name: null, phone: null });

  useEffect(() => {
    getUserData().get().then(snap => {
      const item = snap.data()
      setuser(prevState => ({ ...prevState, name: item?.name, phone: item?.phone }))
    })
  },[])

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>{user.name}</IonListHeader>
          <IonNote>{user.phone}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonItem onClick={handleOut} button={true} color="danger" lines="none">
            <IonIcon slot="start" style={{ color: '#fff' }} icon={logOutOutline} />
            <IonLabel>Cerrar Sesión</IonLabel>
          </IonItem>
        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );

  async function handleOut () {
    try {
      logout().then(() => {
        window.location.href = '/'
      })
    } catch (error) {
      alert(error.message)
    }
  }
};

export default Menu;
