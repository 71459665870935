import Menu from './components/Menu';
import Page from './pages/Page';
import React, { useState, useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonPage, IonContent, IonSpinner } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { getUser } from './firebaseConfig';
import Home from './pages/Home';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import Registry from './pages/Registry';
import Recomendations from './pages/Recomendations';
import SendApp from './pages/SendApp';
import Contact from './pages/Contact';

const App: React.FC = () => {

  const [user,setuser] = useState<string | null>()

  console.log(user)

  useEffect(() => {
    document.body.classList.toggle("dark");
    getUser().then(res => {
      if (res) {
        setuser('true')
        window.history.replaceState({},'','/')
      } else {
        setuser('false')
      }
    })
  },[])

  if (user) {
    if (user === 'false') {
      return (
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/" component={Home} exact={true} />
              <Route path="/login" component={Login} exact={true} />
              <Route path="/recovery" component={Recovery} exact={true} />
              <Route path="/registry" component={Registry} exact={true} />
              <Redirect from="/main" to="/main" exact />
              <Redirect from="/recomendations" to="/main" exact />
              <Redirect from="/contact" to="/main" exact />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      )
    }
    return (
      <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/main" component={Page} exact />
              <Route path="/recomendations" component={Recomendations} exact />
              <Route path="/send-app" component={SendApp} exact />
              <Route path="/contact" component={Contact} exact />
              <Redirect from="/" to="/main" exact />
              <Redirect from="/login" to="/main" exact />
              <Redirect from="/registry" to="/main" exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
    );
  }

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonSpinner name="crescent" color="primary" />
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  )
};

export default App;
