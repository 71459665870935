import React, { useState } from 'react'
import { IonList, IonItem, IonLabel, IonButton, IonCheckbox } from '@ionic/react'
import * as _ from 'lodash';
import { getUserData } from '../../firebaseConfig';

interface Signs {
    fiebre: boolean,
    tos_seca: boolean,
    cansancio: boolean,
    dolor_de_garganta: boolean,
    diarrea: boolean,
    conjuntivitis: boolean,
    dolor_de_cabeza: boolean,
    perdida_olfato: boolean,
    perdida_gusto: boolean,
    erupciones_cutaneas: boolean,
    dificultad_respirar: boolean,
    dolor_pecho: boolean,
    incapacidad_moverse: boolean,
    none: boolean
}

const One: React.FC = () => {

    const [signs, setsigns] = useState<Signs>({
        fiebre: false,
        tos_seca: false,
        cansancio: false,
        dolor_de_garganta: false,
        diarrea: false,
        conjuntivitis: false,
        dolor_de_cabeza: false,
        perdida_olfato: false,
        perdida_gusto: false,
        erupciones_cutaneas: false,
        dificultad_respirar: false,
        dolor_pecho: false,
        incapacidad_moverse: false,
        none: false
    })

    return (
        <IonList>
            <h3 style={{ textAlign: 'center' }}>¿Cuales de los siguientes síntomas tienes?</h3>
            <IonItem>
                <IonLabel>Fiebre</IonLabel>
                <IonCheckbox slot="end" checked={signs.fiebre} onIonChange={e => setsigns(prevState => ({ ...prevState, ['fiebre']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Tos Seca</IonLabel>
                <IonCheckbox slot="end" checked={signs.tos_seca} onIonChange={e => setsigns(prevState => ({ ...prevState, ['tos_seca']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Cansancio</IonLabel>
                <IonCheckbox slot="end" checked={signs.cansancio} onIonChange={e => setsigns(prevState => ({ ...prevState, ['cansancio']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Dolor de Garganta</IonLabel>
                <IonCheckbox slot="end" checked={signs.dolor_de_garganta} onIonChange={e => setsigns(prevState => ({ ...prevState, ['dolor_de_garganta']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Diarrea</IonLabel>
                <IonCheckbox slot="end" checked={signs.diarrea} onIonChange={e => setsigns(prevState => ({ ...prevState, ['diarrea']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Conjuntivitis</IonLabel>
                <IonCheckbox slot="end" checked={signs.conjuntivitis} onIonChange={e => setsigns(prevState => ({ ...prevState, ['conjuntivitis']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Dolor de Cabeza</IonLabel>
                <IonCheckbox slot="end" checked={signs.dolor_de_cabeza} onIonChange={e => setsigns(prevState => ({ ...prevState, ['dolor_de_cabeza']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Pérdida del sentido del olfato</IonLabel>
                <IonCheckbox slot="end" checked={signs.perdida_olfato} onIonChange={e => setsigns(prevState => ({ ...prevState, ['perdida_olfato']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Pérdida del sentido del gusto</IonLabel>
                <IonCheckbox slot="end" checked={signs.perdida_gusto} onIonChange={e => setsigns(prevState => ({ ...prevState, ['perdida_gusto']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Erupciones cutáneas o pérdida del color en los dedos de las manos o de los pies</IonLabel>
                <IonCheckbox slot="end" checked={signs.erupciones_cutaneas} onIonChange={e => setsigns(prevState => ({ ...prevState, ['erupciones_cutaneas']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Dificultad para respirar o sensación de falta de aire</IonLabel>
                <IonCheckbox slot="end" checked={signs.dificultad_respirar} onIonChange={e => setsigns(prevState => ({ ...prevState, ['dificultad_respirar']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Dolor o presión en el pecho</IonLabel>
                <IonCheckbox slot="end" checked={signs.dolor_pecho} onIonChange={e => setsigns(prevState => ({ ...prevState, ['dolor_pecho']: e.detail.checked }))} color="danger" />
            </IonItem>
            <IonItem>
                <IonLabel>Incapacidad para hablar o moverse</IonLabel>
                <IonCheckbox slot="end" checked={signs.incapacidad_moverse} onIonChange={e => setsigns(prevState => ({ ...prevState, ['incapacidad_moverse']: e.detail.checked }))} color="danger" />
            </IonItem>
            
            <IonItem>
                <IonLabel>Ninguna de las Anteriores</IonLabel>
                <IonCheckbox slot="end" checked={signs.none} onIonChange={e => setsigns(prevState => ({ ...prevState, ['none']: e.detail.checked }))} color="danger" />
            </IonItem>
            
            <IonButton onClick={handleSubmit} size="large" color="primary" style={{ marginTop: '1rem' }}  expand="block">Continuar</IonButton>
        </IonList>
    )

    async function handleSubmit() {
        try {
            getUserData().update({...signs, step: 3}).then(() => {
                alert('Ya estamos por terminar !')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default One