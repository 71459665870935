import React, { useState } from 'react'
import { IonList, IonItem, IonLabel, IonButton, IonCheckbox } from '@ionic/react'
import { getUserData } from '../../firebaseConfig';

interface Diseases {
    asma: boolean,
    hipertension: boolean,
    renal: boolean,
    pulmonar: boolean,
    diabetes: boolean,
    hemoglobina: boolean,
    hepatica: boolean,
    none: boolean
}

const Two: React.FC = () => {

    const [diseases, setdiseases] = useState<Diseases>({
        asma: false,
        hipertension: false,
        renal: false,
        pulmonar: false,
        diabetes: false,
        hemoglobina: false,
        hepatica: false,
        none: false
    })

    return (
        <IonList>
            <h3>¿Cuales de las siguientes enfermedades crónicas tienes?</h3>

            <IonItem>
                <IonLabel>Asma</IonLabel>
                <IonCheckbox slot="end" checked={diseases.asma} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['asma']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Hipertensión</IonLabel>
                <IonCheckbox slot="end" checked={diseases.hipertension} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['hipertension']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Enfermedad renal crónica bajo tratamiento con diálisis</IonLabel>
                <IonCheckbox slot="end" checked={diseases.renal} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['renal']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Enfermedad pulmonar crónica</IonLabel>
                <IonCheckbox slot="end" checked={diseases.pulmonar} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['pulmonar']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Diabetes</IonLabel>
                <IonCheckbox slot="end" checked={diseases.diabetes} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['diabetes']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Trastornos de hemoglobina</IonLabel>
                <IonCheckbox slot="end" checked={diseases.hemoglobina} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['hemoglobina']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Enfermedad Hepática</IonLabel>
                <IonCheckbox slot="end" checked={diseases.hepatica} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['hepatica']: e.detail.checked}))} color="light" />
            </IonItem>
            <IonItem>
                <IonLabel>Ninguna de las anteriores</IonLabel>
                <IonCheckbox slot="end" checked={diseases.none} onIonChange={e => setdiseases(prevState => ({ ...prevState, ['none']: e.detail.checked}))} color="light" />
            </IonItem>

            <IonButton onClick={handleSubmit} size="large" color="primary" style={{ marginTop: '1rem' }} expand="block">Terminar</IonButton>
        </IonList>
    )

    async function handleSubmit() {
        try {
            getUserData().update({ ...diseases, step: 4 }).then(() => {
                alert('Hemos terminado')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Two