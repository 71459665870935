import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonSlides, IonSlide, IonImg } from '@ionic/react';
import React from 'react';
import './Page.css';

const Recomendations: React.FC = () => {

    const slideOpts = {
        initialSlide: 0,
        speed: 400
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Recomendaciones - Guasave Sinaloa</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Recomendaciones - Guasave Sinaloa</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonSlides pager={true} options={slideOpts}>
                    <IonSlide>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <IonImg src="/assets/s1.jpg" style={{ width: '80%' }} />
                            <h3 style={{ marginTop: '1.5rem' }}>Lava tus manos constantemente</h3>
                        </div>
                    </IonSlide>
                    <IonSlide>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <IonImg src="/assets/s2.jpg" style={{ width: '80%' }} />
                            <h3 style={{ marginTop: '1.5rem' }}>No salgas de tu casa y si lo haces usa cubrebocas correctamente en todo momento</h3>
                        </div>
                    </IonSlide>
                    <IonSlide>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <IonImg src="/assets/s6.jpg" style={{ width: '80%' }} />
                            <h3 style={{ marginTop: '1.5rem' }}>Manten una sana distancia</h3>
                        </div>
                    </IonSlide>
                </IonSlides>
            </IonContent>
        </IonPage>
    );
};

export default Recomendations;
