import * as firebase from 'firebase'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import moment from 'moment'

const config = {
    apiKey: "AIzaSyCrTl05Ajn7uo9_imbmH6SzslZQk1JW6xs",
    authDomain: "rastreo-covid.firebaseapp.com",
    databaseURL: "https://rastreo-covid.firebaseio.com",
    projectId: "rastreo-covid",
    storageBucket: "rastreo-covid.appspot.com",
    messagingSenderId: "18865115245",
    appId: "1:18865115245:web:6db87da0625560aae26f64",
    measurementId: "G-Q8YMGXHSHY"
}

firebase.initializeApp(config)

export function login (username: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(`${username}@rastreo-covid.web.app`,password)
}

export async function createUser (username: string, password: string, name: string) {
    await firebase.auth().createUserWithEmailAndPassword(`${username}@rastreo-covid.web.app`, password)
    return firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid).set({
        id: firebase.auth().currentUser?.uid,
        password: password,
        timestamp: moment().valueOf(),
        name: name,
        phone: username,
        step: 0,
        rol: 'user'
    })
}

export async function logout () {
    try {
        return firebase.auth().signOut()
    } catch (error) {
        alert(error.message)
    }
}

export function getbanner () {
    return firebase.firestore().collection('banner')
}

export function getMain () {
    return firebase.firestore().collection('utils').doc('main')
}

export async function sendSms (item: any) {
    try {
        const ref = firebase.firestore().collection('sendSms').doc()
        item.id = ref.id
        return ref.set(item)
    } catch (error) {
        return error
    }
}

export async function getSingleUser (phone:string) {
    try {
        const ref = firebase.firestore().collection('users').where('phone','==',phone).get();
        return ref
    } catch (error) {
        return error
    }
}

export async function getUser () {
    try {
        return new Promise((resolve) => {
            const unsuscribe = firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    resolve(user)
                } else {
                    resolve(false)
                }
                unsuscribe()
            })
        })
    } catch (error) {
        console.log(error)
    }
}

export function getUserData () {
    return firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid)
}